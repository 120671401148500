<template>
  <Portlet
    :title="`Daily Total Runs`"
    icon="chart-area"
    class="runChart"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="reportsAvailable">
        <kendo-chart
          ref="chart"
          :category-axis-categories="categories"
          :legend-position="'top'"
          :chart-area-background="''"
          :series-defaults-type="'column'"
          :series-defaults-style="'smooth'"
          :value-axis-labels-format="'{0}'"
          :value-axis-line-visible="false"
          :value-axis-axis-crossing-value="'-10'"
          :category-axis-major-grid-lines-visible="false"
          :category-axis-labels-rotation="'auto'"
          :tooltip-visible="true"
          :tooltip-format="'{0}'"
          :major-unit="'50'"
          :tooltip-template="tooltiptemplate"
          :theme="'sass'"
        >
          <kendo-chart-series-item
            :name="installationId"
            :data="altData"
          />
        </kendo-chart>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: "RunChart",
  mixins: [
    dateTimeMixin,
    responsiveKendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    spotId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      reports: null,
      tooltiptemplate: '#= series.name #: #= value #',
      altData: null,
      categories: null,
    }
  },
  computed: {
    reportsAvailable: function () {
      if (this.altData && this.altData.length > 0) {
        return true;
      }
      return false;
    }
  },
  created () {
    this.getDaylyReports();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    getDaylyReports () {
      this.axios.get(`/Media/GetReportWithSpotId/${ this.installationId }/${ 5 }/${ this.spotId }`)
        .then((response) => {
          this.reports = response.data;
          this.altData = this.reports.map(o => o.count);
          this.categories = this.reports.map(o => this.$options.filters.dateTime_date(o.reportDate));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>